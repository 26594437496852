import React, {useState, useEffect ,Component} from 'react';
import {
    Datagrid,
    Edit, 
    TabbedForm, 
    FormTab, TextInput, DisabledInput,
    ReferenceManyField,
    TextField,
    NumberField,
    DateField,
    SelectInput,
    ReferenceInput,
    translate,
    BooleanInput,
    SelectField,
    Pagination
} from 'react-admin';
import {DateTimeInput} from 'react-admin-date-inputs';
import Button from '@material-ui/core/Button';
import date from '../../_config/date';

import { connect } from 'react-redux';
import { purchaseCancel } from '../../actions/purchasesActions';
import { downloadURI } from "../../_providers/filesProvider";
import PropTypes from 'prop-types';
import config from '../../_config/config';

class CancelPurchaseButton extends Component {
    handleClick = () => {
        const { purchaseCancel, record } = this.props;
        purchaseCancel(record.id, record);
        // how about push and showNotification?
    };
    
    render() {
        const { record, translate } = this.props;
        
        return (
            record && record.status === 2
                ? (
                    <Button color="primary" onClick={this.handleClick}>
                        {translate("resources.customers.actions.purchases.cancel")}
                    </Button>

                )
                : null
        )
    }
}


CancelPurchaseButton.propTypes = {
    purchaseCancel: PropTypes.func.isRequired,
    record: PropTypes.object,
};


const CPB = connect(null, { purchaseCancel} )(translate(CancelPurchaseButton));



class DownloadVoicemailButton extends Component {
    handleClick = () => {
        const { record } = this.props;
        downloadURI(record.id);
    };

    render() {
        const { record, translate } = this.props;

        return (
            record && record.type === "VOICE_MAIL"
                ? (
                    <Button color="primary" onClick={this.handleClick}>
                        {translate("resources.customers.actions.activity.download")}
                    </Button>

                )
                : null
        )
    }
}


DownloadVoicemailButton.propTypes = {
    record: PropTypes.object,
};


const DVB = connect(null, { } )(translate(DownloadVoicemailButton));


const CustomerEdit = props => {

    const {translate} = props;
    
    const [purchaseType, setPurchaseType] = useState(null);
    const [purchaseProduct, setPurchaseProduct] = useState(null);
    const [purchaseIframeSrc, setPurchaseIframeSrc] = useState(null);
    
    const purchaseChange = async (e,v) => {
        setPurchaseType(v)
    };
    
    const purchaseProductChange = async (e,v) => {
        setPurchaseProduct(v);
    };
    
    useEffect(() => {
        updatePurchaseIframeSrc();
    }, [purchaseType, purchaseProduct]);
    
    const updatePurchaseIframeSrc = () => {
        let redirectUrl = "https://direct.tranzila.com/"
            + config.TRANZILA_USERNAME + "/iframenew.php"
            + "?sum=0"
            + "&u71=1"
            + "&currency=1"
            + "&cred_type=1"
            + "&remarks=" 
            + "&lang=il"
            + "&phone="
            + "&contact="
            + "&email=";
        
        
        if (purchaseType === 1 && purchaseProduct != null){
            setPurchaseIframeSrc(redirectUrl);
        } else {
            setPurchaseIframeSrc(null);
        }
        
    };
    
    return (<Edit {...props} undoable={false}>
        <TabbedForm>
            <FormTab label={props.translate("resources.customers.details")}>
                <DisabledInput source="id"/>
                <DisabledInput source="phone"/>
                <TextInput source="first_name"/>
                <TextInput source="last_name"/>
                <TextInput source="email"/>
                <TextInput source="address"/>
                <DisabledInput source="user_agent"/>
                <DisabledInput source="creator"/>
                <DateTimeInput source="created_at"
                               options={{format: date.format,disabled: true }}/>
                <DateTimeInput source="expire_at"
                               options={{format: date.format}}/>
                <BooleanInput source="active"/>
                <SelectInput source="status" choices={[
                    {id: 0, name: props.translate("resources.customers.statuses.disabled")},
                    {id: 1, name: props.translate("resources.customers.statuses.regular")},
                    {id: 2, name: props.translate("resources.customers.statuses.premium")}
                ]}/>

            </FormTab>
            <FormTab label={props.translate("resources.customers.purchases")}>
                <ReferenceManyField reference="purchases" target="customer_id" addLabel={false}
                                    pagination={<Pagination/>}>
                    <Datagrid style={{width: '100%'}} >
                        <TextField source="id"/>
                        <NumberField source="price" sortable={false} options={{style: 'currency', currency: 'ILS'}}/>
                        <SelectField source="status" optionText="name" optionValue="id" sortable={false}
                                     choices={[
                                         {id: 0, name: props.translate("resources.customers.enums.status.pending")},
                                         {id: 1, name: props.translate("resources.customers.enums.status.declined")},
                                         {id: 2, name: props.translate("resources.customers.enums.status.completed")},
                                         {id: 3, name: props.translate("resources.customers.enums.status.refunded")}
                                     ]}/>
                        <TextField source="code" sortable={false}/>
                        <DateField showTime={true} source="created_at" locales="he-IL" options={{hour12: false}}
                                   sortable={false}/>
                        <DateField showTime={true} source="updated_at" locales="he-IL" options={{hour12: false}}
                                   sortable={false}/>
                        <CPB label={translate("resources.customers.actions.purchases.cancel")} />    
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label={props.translate("resources.customers.purchase.name")}>
                <SelectInput label={props.translate("resources.customers.purchase.fields.method")} source="method"
                             choices={[
                                 {id: 0, name: props.translate("resources.customers.purchase.fields.cash")},
                                 {id:1, name: props.translate("resources.customers.purchase.fields.card")}
                             ]}
                            onChange={purchaseChange}
                />

                <ReferenceInput
                    label={props.translate("resources.customers.purchase.fields.product")}
                    source="product"
                    reference="products"
                    onChange={purchaseProductChange}
                >
                    <SelectInput optionText="name" optionValue="id"/>
                </ReferenceInput>
                {
                    purchaseIframeSrc != null && <iframe title="payment-iframe" src={purchaseIframeSrc} style={{
                        width: "421px",
                        height: "515px",
                        border: "1px solid #ddd"
                    }}/>
                }
                {
                    purchaseType === 0 && purchaseProduct != null && <Button type="success" >
                        {props.translate("resources.customers.purchase.fields.purchase")}
                    </Button>
                }
                
            </FormTab>
            <FormTab label={props.translate("resources.customers.activity")}>
                <ReferenceManyField reference="messages" target="customer_id" addLabel={false}
                                    pagination={<Pagination/>}>
                    <Datagrid style={{width: '100%'}}>
                        <TextField source="id" style={{maxWidth: 25}}/>
                        <TextField source="sender"/>
                        <TextField source="message"/>
                        <DateField showTime={true} source="sent_dt" locales="he-IL" options={{hour12: false}}/>
                        <DateField showTime={true} source="received_dt" locales="he-IL" options={{hour12: false}}/>
                        <TextField source="type"/>
                        <DVB label={translate("resources.customers.actions.activity.download")} />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label={props.translate("resources.customers.sms")}>
                <ReferenceManyField reference="sms" target="customer_id" addLabel={false}
                                    pagination={<Pagination/>}>
                    <Datagrid style={{width: '100%'}}>
                        <TextField source="id" style={{maxWidth: 25}}/>
                        <TextField source="message" style={{maxWidth: 400}}/>
                        <DateField showTime={true} source="sent_dt" locales="he-IL" options={{hour12: false}}/>
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label={props.translate("resources.customers.subscriptions")}>
                <ReferenceManyField reference="subscriptions" target="customer_id" addLabel={false}
                                    pagination={<Pagination/>}>
                    <Datagrid style={{width: '100%'}}>
                        <TextField source="id" style={{maxWidth: 25}}/>
                        <TextField source="subscription_type_id"/>
                        <DateField showTime={true} source="active_from" locales="he-IL" options={{hour12: false}}/>
                        <DateField showTime={true} source="active_to" locales="he-IL" options={{hour12: false}}/>
                        <TextField source="status"/>
                        <TextField source="service_id"/>
                        <TextField source="purchase_token" style={{maxWidth: 400}}/>
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </Edit>)
};

export default translate(CustomerEdit);
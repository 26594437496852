import React from 'react';
import {
    TabbedForm,
    FormTab,
    Edit, 
    TextInput, 
    DisabledInput,
    ReferenceArrayInput,
    SelectArrayInput,
    translate,
    ReferenceManyField,
    Datagrid,
    TextField,
    NumberField,
    SelectField,
    DateField,
    Pagination
} from 'react-admin';

const required = (message = 'myroot.validation.required') =>
    (value, allValues, props) => value ? undefined : props.translate(message);

const UserEdit = props => {

    const {translate} = props;
    
    return (<Edit {...props} undoable={false}>
        <TabbedForm>
            <FormTab label={props.translate("resources.users.details")}>
                <DisabledInput source="id"/>
                <TextInput source="username" validate={required()}/>
                <TextInput source="email" validate={required()}/>
                <TextInput source="phone" validate={required()}/>
                <TextInput source="password"/>
                <ReferenceArrayInput
                    label={translate("resources.users.fields.roles")}
                    source="role_ids"
                    reference="roles"
                    validate={required()}
                >
                    <SelectArrayInput optionText="name" optionValue="id"/>
                </ReferenceArrayInput>
            </FormTab>
            <FormTab label={props.translate("resources.users.sales")}>
                <ReferenceManyField 
                    pagination={<Pagination/>}
                    reference="purchases" 
                    addLabel={false}
                    target="user_id" >
                        <Datagrid style={{width: '100%'}}>
                            <TextField source="id" style={{maxWidth: 25}}/>
                            <TextField source="phone" style={{maxWidth: 75}}/>
                            <NumberField source="price" sortable={false} options={{style: 'currency', currency: 'ILS'}}/>
                            <SelectField source="status" optionText="name" optionValue="id" sortable={false}
                                         choices={[
                                             {id: 0, name: props.translate("resources.customers.enums.status.pending")},
                                             {id: 1, name: props.translate("resources.customers.enums.status.declined")},
                                             {id: 2, name: props.translate("resources.customers.enums.status.completed")},
                                             {id: 3, name: props.translate("resources.customers.enums.status.refunded")}
                                         ]}/>
                            <TextField source="code" sortable={false}/>
                            <DateField showTime={true} source="created_at" locales="he-IL" options={{hour12: false}}
                                       sortable={false}/>
                            <DateField showTime={true} source="updated_at" locales="he-IL" options={{hour12: false}}
                                       sortable={false}/>
                        </Datagrid>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </Edit>)
};

export default translate(UserEdit);
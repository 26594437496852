import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const required = (message = 'myroot.validation.required') =>
    (value, allValues, props) => value ? undefined : props.translate(message);
    
const CustomerCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="fullname" validate={required()}  />
            <TextInput source="email" validate={required()}   />
            <TextInput source="phone" validate={required()} />
        </SimpleForm>
    </Create>
);

export default CustomerCreate;
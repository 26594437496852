import React from 'react';
import { List, Datagrid, TextField, EmailField, DateField, BooleanField,
    Filter,
    TextInput,
    translate
} from 'react-admin';

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label={props.translate("resources.customers.filters.search")} source="query" alwaysOn />
    </Filter>
);


const CustomerList = props => (
    <List {...props} bulkActionButtons={null} filters={<Filters translate={props.translate}/>}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <EmailField source="email" />
            <TextField source="phone" />
            <DateField showTime={true} source="created_at" locales="he-IL" options={{hour12: false}} />
            <DateField showTime={true} source="expire_at" locales="he-IL" options={{hour12: false}} />
            <BooleanField source="active" />
        </Datagrid>
    </List>
);

export default translate(CustomerList);
import config from "../_config/config";

async function downloadURI(recordId) {

    return new Promise((res,rej) => {
        let xhr = new XMLHttpRequest();

        xhr.responseType = "blob";
        xhr.addEventListener("load", (ev) => {
            if (xhr.response == null) {
                rej("EMPTY_FILE");
                return;
            }
            var windowUrl = window.URL || window.webkitURL;
            var url = windowUrl.createObjectURL(xhr.response);
            var anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `${recordId}.wav`;
            document.body.appendChild(anchor);
            anchor.click();
            anchor.parentNode.removeChild(anchor);
            windowUrl.revokeObjectURL(url);
        });

        xhr.addEventListener("error", (err) => {
            rej(err.message)
        });

        xhr.open("GET", `${config.API_HOST}/files/${recordId}`);
        xhr.setRequestHeader("Authorization", `Bearer ${localStorage.getItem("token")}`);
        xhr.send();
    })
}


export {
    downloadURI
}
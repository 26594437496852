import React from 'react';
import {Edit, SimpleForm, TextInput, NumberInput, DisabledInput, SelectInput, BooleanInput} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const required = (Product = 'myroot.validation.required') =>
    (value, allValues, props) => value ? undefined : props.translate(Product);

const ProductEdit = props => (
    <Edit {...props} undoable={false}>
        <SimpleForm>
            <DisabledInput source="id"/>
            <TextInput source="name" validate={required()}/>
            <RichTextInput source="description" validate={required()} />
            <NumberInput source="price" validate={required()}/>
            <NumberInput source="order" validate={required()}/>
            <SelectInput source="type" choices={[
                {id: 0, name: "Daily"},
                {id: 1, name: "Weekly"},
                {id: 2, name: "TwoWeekly"},
                {id: 3, name: "Monthly"},
                {id: 4, name: "Quarterly"},
                {id: 5, name: "Semiannual"},
                {id: 6, name: "Annual"},
            ]}>
            </SelectInput>
            <BooleanInput source="is_recurrent"/>
        </SimpleForm>
    </Edit>
);

export default ProductEdit;
import { UPDATE } from 'react-admin';

export const PURCHASE_CANCEL = 'PURCHASE_CANCEL';
export const purchaseCancel = (id, data, basePath) => ({
    type: PURCHASE_CANCEL,
    payload: { id, data: { ...data, status: 3 } },
    meta: { 
        fetch: UPDATE, 
        resource: 'purchases',
        onSuccess: {
            notification: {
                body: 'resources.customers.actions.purchases.success',
                level: 'info',
            }
        },
        onFailure: {
            notification: {
                body: 'resources.customers.actions.purchases.failure',
                level: 'warning',
            }
        }
    },
});
import config from '../_config/config';

const getPurchases = (userId, fromDate, toDate) => {
    return new Promise((res,rej) => {
        let xhr = new XMLHttpRequest();

        xhr.addEventListener("load", (ev) => {
            if (xhr.responseText === "") return null;
            res(JSON.parse(xhr.responseText))
        });

        xhr.addEventListener("error", (err) => {
            rej(err.message)
        });
        let url = `${config.API_HOST}/purchases?user_id=${userId}&from_date=${fromDate}&to_date=${toDate}`;
        
        xhr.open("GET", url);
        xhr.setRequestHeader("content-type", "application/json");
        xhr.setRequestHeader("Authorization", `Bearer ${localStorage.getItem("token")}`);
        xhr.send();
    })
};

export {
    getPurchases,
}
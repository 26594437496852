import React from 'react';
import {Provider} from 'react-redux';
import {Route, Router} from 'react-router-dom';
import config from './_config/config';
import PropTypes from 'prop-types';

import {fetchUtils, Admin, Resource} from 'react-admin'

import HebrewMessages from 'ra-language-hebrew';


import jsonProvider from "./_providers/dataJsonProvider";
import authProvider from "./_providers/authProvider";
import defaultMessages from 'ra-language-english';

import {CustomerList, CustomerCreate, CustomerEdit} from "./models/customers";
import {ProductList, ProductCreate, ProductEdit} from "./models/products";
import {UserList, UserCreate, UserEdit} from './models/users';
import {PurchaseList} from './models/purchases';
import {ResellersList} from './models/resellers';
import {RoleList} from "./models/roles";
import Dashboard from './models/dashboard';

import './assets/base.scss';

import {createBrowserHistory} from "history";

import createAdminStore from './config/configureStore';
import domainMessage from './i18n';
import Layout from './Layout';


const theme = {
    direction: 'rtl',
};

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: "application/json"});
    }
    const token = localStorage.getItem("token");
    options.headers.set("Authorization", `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

// side effects
const dataProvider = jsonProvider(config.API_HOST, httpClient);

const messages = {
    "he": {
        ...HebrewMessages,
        ...domainMessage.he
    },
    "en": {
        ...defaultMessages,
        ...domainMessage.he
    }
};

const i18nProvider = locale => {
    return messages[locale];
};


const history = createBrowserHistory();

const customRoutes = [
    <Route exact path="/dashboard" component={Dashboard}/>
];

class App extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.store = createAdminStore({
            authProvider,
            dataProvider,
            i18nProvider,
            history
        });
    }

    getChildContext() {
        return {
            store: this.store
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <Provider store={this.store}>
                <Router history={history}>
                    <Admin
                        locale={"he"}
                        theme={theme}
                        authProvider={authProvider}
                        dataProvider={dataProvider}
                        history={history}
                        appLayout={Layout}
                        customRoutes={customRoutes}>
                        <Resource
                            name="customers"
                            list={CustomerList}
                            create={CustomerCreate}
                            edit={CustomerEdit}
                            icon='pe-7s-users'/>

                        <Resource
                            name="products"
                            list={ProductList}
                            create={ProductCreate}
                            edit={ProductEdit}
                            icon='pe-7s-diamond'
                        />
                        <Resource
                            name="users"
                            list={UserList}
                            create={UserCreate}
                            edit={UserEdit}
                            icon='pe-7s-id'/>

                        <Resource
                            name="purchases"
                            list={PurchaseList}
                            icon='pe-7s-id'/>

                        <Resource
                            name="roles"
                            icon="pe-7s-id"
                            list={RoleList}
                        />
                        
                        <Resource
                            name="resellers"
                            icon="pe-7s-id"
                            list={ResellersList} />

                        <Resource name="users-roles"/>
                        <Resource name="messages"/>
                        <Resource name="sms"/>
                        <Resource name="subscriptions"/>

                    </Admin>
                </Router>
            </Provider>

        );
    }
}

App.childContextTypes = {
    store: PropTypes.object
};


export default App;
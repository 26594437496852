import React, {  Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    setSidebarVisibility,
} from 'react-admin';
import cx from 'classnames';
import ResizeDetector from 'react-resize-detector';

import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';

import { changeLocale as changeLocaleAction } from 'react-admin';

import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';

class Layout extends Component {
    componentWillMount() {
        this.props.setSidebarVisibility(true);
        this.props.changeLocale('he');
        this.theme = createMuiTheme({
            direction: "rtl"
        })
    }
    
    render() {
        const {
            children,
            open,
            title,
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
        } = this.props;
        
        return (
            <ResizeDetector
            handleWidth
            render={({ width }) => (
                <Fragment>
                    <div className={cx(
                        "app-container app-theme-" + colorScheme,
                        {'fixed-header': enableFixedHeader},
                        {'fixed-sidebar': enableFixedSidebar || width < 1250},
                        {'fixed-footer': enableFixedFooter},
                        {'closed-sidebar': enableClosedSidebar || width < 1250},
                        {'closed-sidebar-mobile': closedSmallerSidebar || width < 1250},
                        {'sidebar-mobile-open': enableMobileMenu},
                        {'body-tabs-shadow-btn': enablePageTabsAlt},
                    )}>
                        <AppHeader open={open} title={title}/>
                        <div className="app-main">
                            <AppSidebar/>
                            <div className="app-main__outer" style={{paddingLeft:0, paddingRight:280}}>
                                <div className="app-main__inner">
                                    <MuiThemeProvider theme={this.theme} >
                                    {children}
                                    </MuiThemeProvider>
                                </div>
                                <AppFooter/>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        />
        )
    }
}

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    dashboard: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),
    isLoading: PropTypes.bool.isRequired,
    // logout: PropTypes.func.isRequired,
    setSidebarVisibility: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

const mapStateToProp = state => ({
    isLoading: state.admin.loading > 0,
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default connect(mapStateToProp, { setSidebarVisibility, changeLocale: changeLocaleAction })(Layout);
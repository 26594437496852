import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from "react-admin";
import config from '../_config/config';
// import decodeJwt from "jwt-decode";

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(
            `${
            config.API_HOST
            }/users/login?username=${username}&password=${password}`,
            {
                method: "GET",
                dataType: "json",
                contentType: "json"
            }
        );
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(token => {
                // let decodedJWT = decodeJwt(token.access_token);
                localStorage.setItem("token", token.access_token);
            });
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem("token");
        // localStorage.removeItem('role');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const status = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem("token");
            return Promise.reject();
        }
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem("token")
            ? Promise.resolve()
            : Promise.reject({ redirectTo: "/login" });
    }
    // if (type === AUTH_GET_PERMISSIONS) {
    //     const role = localStorage.getItem('role');
    //     return role ? Promise.resolve(role) : Promise.reject();
    // }
    return Promise.reject("Unknown method");
};

import React from 'react';
import {
    TextInput,
    translate,
    Datagrid,
    TextField,
    NumberField,
    SelectField,
    DateField,
    List,
    Filter,
    CardActions,
    ExportButton
} from 'react-admin';
import {connect} from 'react-redux';
import moment from 'moment';
import {DateTimeInput} from 'react-admin-date-inputs';
import ExcellentExport from 'excellentexport';


const PurchasesFilters = (props) => {
    return <Filter {...props}>
        <TextInput source="user_name" label={props.translate("resources.purchases.filters.user")}/>
        <DateTimeInput label={props.translate("resources.purchases.filters.from_date")}
                       source="from_date"
                       alwaysOn
                       options={{format: 'YYYY-MM-dd HH:mm:ss', ampm: false, clearable: true, isrequired: "false"}}/>
        <DateTimeInput label={props.translate("resources.purchases.filters.to_date")}
                       source="to_date"
                       alwaysOn
                       options={{format: 'YYYY-MM-dd HH:mm:ss', ampm: false, clearable: true, isrequired: "false"}}/>
    </Filter>
};

const PurchasesActions = ({
                              currentSort,
                              displayedFilters,
                              filters,
                              filterValues,
                              resource,
                              showFilter,
                              total,
                              exporter,
                              translate
                          }) => {


    return <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}

        <ExportButton
            label={translate("action.export")}
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
        <a style={{display: 'none'}} download="Purchases.xlsx" href="./" id="anchorNewApi-xlsx">exporter</a>

    </CardActions>
};

const TPurchasesActions = translate(PurchasesActions);


const TPurchasesFilters = translate(PurchasesFilters);


const PurchaseList = props => {

    const {translate, id, filter_form} = props;

    let now = new Date();

    let currentDate = new Date(now.getFullYear(), now.getMonth(), 1).toISOString();
    let firstDay = new Date(now.getFullYear(), now.getMonth() - 1, 1).toISOString();


    const exporter = (purchases, filterForm) => {
        const purchaseCompleted = purchases
            .filter(p => p.status === 2);

        const groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };


        const purchasesCanceled = purchases
            .filter(p => p.status === 3);


        let sheets = [];


        const purchaseGroupByPrice = groupBy(purchaseCompleted, "price");
        for (let g in purchaseGroupByPrice) {
            sheets.push({
                name: g, // Sheet name
                from: {
                    array: purchaseGroupByPrice[g].map(purchase => {
                        return [
                            purchase.id,
                            purchase.phone,
                            purchase.price,
                            purchase.code,
                            new moment(purchase.created_at).format("YYYY-MM-DD hh:mm")
                        ]
                    }),
                }
            })
        }

        sheets.push({
            name: translate("resources.customers.enums.status.refunded"), // Sheet name
            from: {
                array: purchasesCanceled.map(purchase => {
                    return [
                        purchase.id,
                        purchase.phone,
                        purchase.price,
                        purchase.code,
                        new moment(purchase.created_at).format("YYYY-MM-DD hh:mm")
                    ]
                }),
            }
        });

        let anchor = "anchorNewApi-xlsx";
        
        let exportName = "";
        if (filterForm) {
            if (filterForm.values.user_name) {
                exportName += filterForm.values.user_name.replace(" ", "");
            } else {
                exportName += "כללי"
            }
            if (filterForm.values.from_date) {
                exportName += filterForm.values.from_date
            }
            if (filterForm.values.to_date) {
                exportName += filterForm.values.to_date
            }
        }

        let options = {
            anchor,
            format: 'xlsx',
            filename: exportName || "Purchases"
        };

        ExcellentExport.convert(options, sheets);
        document.getElementById(anchor).click();
    };


    return (
        <List {...props}
              actions={<TPurchasesActions translate={translate}/>}
              hasCreate={false}
              filterDefaultValues={{from_date: firstDay, to_date: currentDate}}
              filters={<TPurchasesFilters translate={translate}/>}
              style={{padding: '16px 0 0 0', width: '100%'}}
              filter={{user_id: id}}
              exporter={(purchases) => {
                  exporter(purchases, filter_form)
              }}>
            <Datagrid style={{width: '100%'}}>
                <TextField source="id" style={{maxWidth: 25}}/>
                <TextField source="user_name" style={{maxWidth: 75}}/>
                <TextField source="phone" style={{maxWidth: 75}}/>
                <NumberField source="price" sortable={false} options={{style: 'currency', currency: 'ILS'}}/>
                <SelectField source="status" optionText="name" optionValue="id" sortable={false}
                             choices={[
                                 {id: 0, name: props.translate("resources.customers.enums.status.pending")},
                                 {id: 1, name: props.translate("resources.customers.enums.status.declined")},
                                 {id: 2, name: props.translate("resources.customers.enums.status.completed")},
                                 {id: 3, name: props.translate("resources.customers.enums.status.refunded")}
                             ]}/>
                <TextField source="code" sortable={false}/>
                <DateField showTime={true} source="created_at" locales="he-IL" options={{hour12: false}}
                           sortable={false}/>
                <DateField showTime={true} source="updated_at" locales="he-IL" options={{hour12: false}}
                           sortable={false}/>
            </Datagrid>

        </List>
    )
};


function mapStateToProps(state) {
    const {form} = state;
    return {
        filter_form: form.filterForm
    }
}


export default connect(mapStateToProps, {})(translate(PurchaseList));
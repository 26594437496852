import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { getResources,translate } from 'react-admin';

import MetisMenu from 'react-metismenu';
import RouterLink from 'react-metismenu-router-link';

const AppNav = ({ translate, resources, onMenuClick, logout }) => {
    
    resources
        .forEach(r => {
            r.label = translate(`resources.${r.name}.name`);
            r.to = `/${r.name}`
        });
    
    return (
        <Fragment>
            <h5 className="app-sidebar__heading">Menu</h5>
            <MetisMenu
                content={[{ name: "dashboard", to: "/dashboard", label: translate(`resources.dashboard.name`), icon:"pe-7s-rocket"}]}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
                onSelected={onMenuClick}
                LinkComponent={RouterLink}/>
            <MetisMenu
                content={resources.filter(r => r.hasList)}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
                onSelected={onMenuClick}
                LinkComponent={RouterLink}/>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    resources: getResources(state),
});

export default connect(mapStateToProps)(translate(AppNav));
import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, BooleanInput, SelectInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const ProductCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <RichTextInput source="description" />
            <NumberInput source="price" />
            <NumberInput source="order" />
            <SelectInput source="type" choices={[
                { id : 0, name: "Daily"},
                { id : 1, name: "Weekly"},
                { id : 2, name: "TwoWeekly"},
                { id : 3, name: "Monthly"},
                { id : 4, name: "Quarterly"},
                { id : 5, name: "Semiannual"},
                { id : 6, name: "Annual"},
            ]} >
            </SelectInput>
            <BooleanInput source="is_recurrent"/>
        </SimpleForm>
    </Create>
);

export default ProductCreate;
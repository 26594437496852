import React from 'react';
import {
    Create, SimpleForm, TextInput,
    ReferenceArrayInput,
    SelectArrayInput,
    translate
} from 'react-admin';

const required = (message = 'myroot.validation.required') =>
    (value, allValues, props) => value ? undefined : props.translate(message);

const UserCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="username" validate={required()}/>
            <TextInput source="email" validate={required()}/>
            <TextInput source="phone" validate={required()}/>
            <TextInput source="password" validate={required()}/>
            <ReferenceArrayInput
                label={props.translate("resources.users.fields.roles")}
                source="role_ids"
                reference="roles"
                validate={required()}
            >
                <SelectArrayInput optionText="name" optionValue="id"/>
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);

export default translate(UserCreate);
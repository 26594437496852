import config from '../_config/config';

const getDashboard = () => {
    return new Promise((res,rej) => {
        let xhr = new XMLHttpRequest();

        xhr.addEventListener("load", (ev) => {
            if (xhr.responseText === "") return null;
            res(JSON.parse(xhr.responseText))
        });

        xhr.addEventListener("error", (err) => {
            rej(err.message)
        });

        xhr.open("GET", `${config.API_HOST}/dashboard`);
        xhr.setRequestHeader("content-type", "application/json");
        xhr.setRequestHeader("Authorization", `Bearer ${localStorage.getItem("token")}`);
        xhr.send();
    })
};

export {
    getDashboard,
}
import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

const RoleList = props => (
    <List {...props}  bulkActionButtons={null}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
        </Datagrid>
    </List>
);


export default RoleList;
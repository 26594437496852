import React, {Fragment, useEffect, useState} from 'react';

import {
    Col,
    Container,
    Row,
    CardHeader
} from "reactstrap";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import PageTitleAlt2 from '../../Layout/AppMain/PageTitleAlt2';

import {getDashboard} from '../../_providers/dashboardProvider';

import {translate} from 'react-admin';

const Dashboard = props => {
    const [dashboard, setDashboard] = useState({
        customers: {},
        androidSubs: [],
        iphoneSubs: [],
        totalCompletedResellers: [],
        totalCompletedWebsite: []
    });


    useEffect(() => {
        getDashboard()
            .then(data => {
                setDashboard(data)
            });
    }, []);

    return (
        <Fragment>
            <ReactCSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <PageTitleAlt2
                    heading={props.translate("resources.dashboard.name")}
                    icon="pe-7s-graph icon-gradient bg-ripe-malin"
                />
                <Fragment>
                    <Container fluid>
                        <Row>
                            <Col md="6" xl="3">
                                <div className="card mb-3 widget-content">
                                    <div className="widget-content-outer">
                                        <div className="widget-content-wrapper">
                                            <div className="widget-content-left">
                                                <div className="widget-heading">
                                                    {props.translate("resources.dashboard.active_customers_purchases_count")}
                                                </div>
                                            </div>
                                            <div className="widget-content-right">
                                                <div className="widget-numbers text-success">
                                                    {dashboard.customers.activeCustomersPurchasesCount}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6" xl="3">
                                <div className="card mb-3 widget-content">
                                    <div className="widget-content-outer">
                                        <div className="widget-content-wrapper">
                                            <div className="widget-content-left">
                                                <div className="widget-heading">
                                                    {props.translate("resources.dashboard.active_customers_subs_count")}
                                                </div>
                                            </div>
                                            <div className="widget-content-right">
                                                <div className="widget-numbers text-success">
                                                    {dashboard.customers.activeCustomersSubsCount}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6" xl="3">
                                <div className="card mb-3 widget-content">
                                    <div className="widget-content-outer">
                                        <div className="widget-content-wrapper">
                                            <div className="widget-content-left">
                                                <div className="widget-heading">
                                                    {props.translate("resources.dashboard.active_customers_test_count")}
                                                </div>
                                            </div>
                                            <div className="widget-content-right">
                                                <div className="widget-numbers text-success">
                                                    {dashboard.customers.activeCustomersTestCount}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6" xl="3">
                                <div className="card mb-3 widget-content">
                                    <div className="widget-content-outer">
                                        <div className="widget-content-wrapper">
                                            <div className="widget-content-left">
                                                <div className="widget-heading">
                                                    {props.translate("resources.dashboard.expired_new_customers_30")}
                                                </div>
                                            </div>
                                            <div className="widget-content-right">
                                                <div className="widget-numbers text-success">
                                                    {dashboard.customers.expiredNewCustomers30}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <CardHeader className="mbg-3 h-auto pl-0 pr-0 bg-transparent no-border">
                            <div className="card-header-title fsize-2 text-capitalize font-weight-normal">
                                {props.translate("resources.dashboard.iphone_title")}
                            </div>
                        </CardHeader>

                        {
                            dashboard.iphoneSubs.map((is) => {
                                return (<Row key={is.p_id}>
                                    <Col md="6" xl="6">
                                        <div className="card mb-3 widget-content">
                                            <div className="widget-content-outer">
                                                <div className="widget-content-wrapper">
                                                    <div className="widget-content-left">
                                                        <div className="widget-heading">
                                                            {props.translate(`resources.dashboard.count`)}: {is.product}
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-success">
                                                            {is.count}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6" xl="6">
                                        <div className="card mb-3 widget-content">
                                            <div className="widget-content-outer">
                                                <div className="widget-content-wrapper">
                                                    <div className="widget-content-left">
                                                        <div className="widget-heading">
                                                            {props.translate("resources.dashboard.sum")}: {is.product}
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-success">
                                                            {is.sum} ₪
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>)
                            })

                        }

                        <CardHeader className="mbg-3 h-auto pl-0 pr-0 bg-transparent no-border">
                            <div className="card-header-title fsize-2 text-capitalize font-weight-normal">
                                {props.translate("resources.dashboard.android_title")}
                            </div>
                        </CardHeader>

                        {
                            dashboard.androidSubs.map((is) => {
                                return (<Row key={is.p_id}>
                                    <Col md="6" xl="6">
                                        <div className="card mb-3 widget-content">
                                            <div className="widget-content-outer">
                                                <div className="widget-content-wrapper">
                                                    <div className="widget-content-left">
                                                        <div className="widget-heading">
                                                            {props.translate(`resources.dashboard.count`)}: {is.product}
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-success">
                                                            {is.count}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6" xl="6">
                                        <div className="card mb-3 widget-content">
                                            <div className="widget-content-outer">
                                                <div className="widget-content-wrapper">
                                                    <div className="widget-content-left">
                                                        <div className="widget-heading">
                                                            {props.translate("resources.dashboard.sum")}: {is.product}
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-success">
                                                            {is.sum} ₪
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>)
                            })

                        }

                        <CardHeader className="mbg-3 h-auto pl-0 pr-0 bg-transparent no-border">
                            <div className="card-header-title fsize-2 text-capitalize font-weight-normal">
                                {props.translate("resources.dashboard.reseller_title")}
                            </div>
                        </CardHeader>

                        {
                            dashboard.totalCompletedResellers.map((is) => {
                                return (<Row key={is.p_id}>
                                    <Col md="6" xl="6">
                                        <div className="card mb-3 widget-content">
                                            <div className="widget-content-outer">
                                                <div className="widget-content-wrapper">
                                                    <div className="widget-content-left">
                                                        <div className="widget-heading">
                                                            {props.translate(`resources.dashboard.count`)}: {is.product}
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-success">
                                                            {is.count}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6" xl="6">
                                        <div className="card mb-3 widget-content">
                                            <div className="widget-content-outer">
                                                <div className="widget-content-wrapper">
                                                    <div className="widget-content-left">
                                                        <div className="widget-heading">
                                                            {props.translate("resources.dashboard.sum")}: {is.product}
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-success">
                                                            {is.sum} ₪
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>)
                            })

                        }

                        <CardHeader className="mbg-3 h-auto pl-0 pr-0 bg-transparent no-border">
                            <div className="card-header-title fsize-2 text-capitalize font-weight-normal">
                                {props.translate("resources.dashboard.website_title")}
                            </div>
                        </CardHeader>

                        {
                            dashboard.totalCompletedWebsite.map((is) => {
                                return (<Row key={is.p_id}>
                                    <Col md="6" xl="6">
                                        <div className="card mb-3 widget-content">
                                            <div className="widget-content-outer">
                                                <div className="widget-content-wrapper">
                                                    <div className="widget-content-left">
                                                        <div className="widget-heading">
                                                            {props.translate(`resources.dashboard.count`)}: {is.product}
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-success">
                                                            {is.count}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6" xl="6">
                                        <div className="card mb-3 widget-content">
                                            <div className="widget-content-outer">
                                                <div className="widget-content-wrapper">
                                                    <div className="widget-content-left">
                                                        <div className="widget-heading">
                                                            {props.translate("resources.dashboard.sum")}: {is.product}
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-success">
                                                            {is.sum} ₪
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>)
                            })

                        }

                    </Container>
                </Fragment>
            </ReactCSSTransitionGroup>
        </Fragment>
    )

};

export default translate(Dashboard);
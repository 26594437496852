import React from 'react';
import {
    TextInput,
    translate,
    Datagrid,
    TextField,
    List,
    Filter,
} from 'react-admin';
import {DateTimeInput} from 'react-admin-date-inputs';
import ResellerExportButton from './reseller-export-button';

const PurchasesFilters = (props) => {
    return <Filter {...props}>
        <TextInput source="username" label={props.translate("resources.purchases.filters.user")}/>
        <DateTimeInput label={props.translate("resources.purchases.filters.from_date")}
                       source="fromDate"
                       alwaysOn
                       options={{ format: 'dd-MM-yyyy HH:mm:ss', ampm: false, isRequired: false}}/>
        <DateTimeInput label={props.translate("resources.purchases.filters.to_date")}
                       source="toDate"
                       alwaysOn
                       options={{ format: 'dd-MM-yyyy HH:mm:ss', ampm: false, isRequired: false}}/>
    </Filter>
};


const TPurchasesFilters = translate(PurchasesFilters);

const ConditionalResellerExportButton = props => {
    const { record } = props;
    return  (record.yearly + record.semiannual + record.monthly) > 0 ? <ResellerExportButton {...props}/> : null
    
};


const ResellersList = props => {
    const {translate } = props;

    let now = new Date();
    let currentDate = new Date(now.getFullYear(), now.getMonth(), 1).toISOString();
    let firstDay = new Date(now.getFullYear(), Math.min(now.getMonth() - 1, 1), 1).toISOString();
    
    return (
        <List {...props}
              hasCreate={false}
              exporter={false}
              filterDefaultValues={{fromDate: firstDay, toDate: currentDate}}
              filters={<TPurchasesFilters translate={translate}/>}
              style={{padding: '16px 0 0 0', width: '100%'}}>
            <Datagrid style={{width: '100%'}}>
                <TextField source="id" style={{maxWidth: 25}}/>
                <TextField source="username" style={{maxWidth: 75}}/>
                <TextField source="yearly" style={{maxWidth: 75}}/>
                <TextField source="semiannual" style={{maxWidth: 75}}/>
                <TextField source="monthly" style={{maxWidth: 75}}/>
                <ConditionalResellerExportButton />
            </Datagrid>

        </List>
    )
};

export default translate(ResellersList);
// in src/comments/ApproveButton.js
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';
import moment from "moment";
import ExcellentExport from "excellentexport";

import {getPurchases} from '../../_providers/resellerDataProvider.js';

const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

class ResellerExportButton extends Component {
    handleClick = async () => {
        const {record, translate, filter_form} = this.props;

        let purchases = await getPurchases(record.id, filter_form.values.fromDate, filter_form.values.toDate);

        let purchaseCompleted = purchases
            .filter(p => p.status === 2);

        const purchasesCanceled = purchases
            .filter(p => p.status === 3);
        let sheets = [];

        const purchaseGroupByPrice = groupBy(purchaseCompleted, "price");
        for (let g in purchaseGroupByPrice) {
            sheets.push({
                name: g, // Sheet name
                from: {
                    array: purchaseGroupByPrice[g].map(purchase => {
                        return [
                            purchase.id,
                            purchase.phone,
                            purchase.price,
                            purchase.code,
                            new moment(purchase.created_at).format("YYYY-MM-DD hh:mm")
                        ]
                    }),
                }
            })
        }

        sheets.push({
            name: translate("resources.customers.enums.status.refunded"), // Sheet name
            from: {
                array: purchasesCanceled.map(purchase => {
                    return [
                        purchase.id,
                        purchase.phone,
                        purchase.price,
                        purchase.code,
                        new moment(purchase.created_at).format("YYYY-MM-DD hh:mm")
                    ]
                }),
            }
        });


        let exportName = "";
        if (filter_form) {
            exportName += record.username.replace(" ", "") + "_";
            exportName += filter_form.values.fromDate
                .replace(/T/, "_")
                .replace(/\.000Z/,"_")
                .replace(/-/g,"_") + "_";
            exportName += filter_form.values.toDate
                .replace(/T/, "_")
                .replace(/\.000Z/,"_")
                .replace(/-/g,"_");
        }

        let anchor = "anchorNewApi-xlsx";
        let options = {
            anchor,
            format: 'xlsx',
            filename: exportName || "Purchases"
        };

        ExcellentExport.convert(options, sheets);
        document.getElementById(anchor).click();
    };

    render() {
        const {translate} = this.props;
        return <div>
            <Button onClick={this.handleClick}>
                {translate("resources.resellers.fields.export")}
            </Button>
            <a style={{display: 'none'}} download="Purchases.xlsx" href="./" id="anchorNewApi-xlsx">exporter</a>
        </div>;
    }
}

ResellerExportButton.propTypes = {
    record: PropTypes.object,
};

const translatedBtn = translate(ResellerExportButton);

function mapStateToProps(state) {
    const {form} = state;
    return {
        filter_form: form.filterForm
    }
}

export default connect(mapStateToProps, {})(translatedBtn);
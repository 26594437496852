import React from 'react';
import { List, Datagrid, TextField, EmailField,
    ReferenceManyField, SingleFieldList, ChipField,translate} from 'react-admin';

const UserList = props => (
    <List {...props}  bulkActionButtons={null}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="username" />
            <EmailField source="email" />
            <TextField source="phone" />
            <ReferenceManyField label={props.translate("resources.users.fields.roles")} reference="users-roles" target="userId">
                <SingleFieldList>
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceManyField>
        </Datagrid>
    </List>
);


export default translate(UserList);
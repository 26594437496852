import React, {Fragment} from 'react';

import {
    DropdownToggle, DropdownMenu,
    Button, UncontrolledButtonDropdown
} from 'reactstrap';

import {
    faAngleDown,

} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';
import avatar1 from '../../../assets/utils/images/avatars/e594bbf8c743a6e7f20f1d5028c29b5a.png';

class UserBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
    }

    render() {
        const {logout} = this.props;

        return (
            <Fragment>
                <div className="header-btn-lg" style={{padding: "0rem 1.5rem 0rem 0rem", marginLeft: 0, marginRight:"1.5rem"}}>
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="link" className="p-0">
                                        <img width={42} className="rounded-circle" src={avatar1} alt=""/>
                                        <FontAwesomeIcon className="mr-2 opacity-8" icon={faAngleDown}/>
                                    </DropdownToggle>
                                    <DropdownMenu left={"left"} className="rm-pointers dropdown-menu-lg">
                                        <div className="dropdown-menu-header" style={{ marginBottom: "-0.65rem"}}>
                                            <div className="dropdown-menu-header-inner bg-info">
                                                <div className="menu-header-image opacity-2"
                                                     style={{
                                                         backgroundImage: 'url(' + city3 + ')'
                                                     }}
                                                />
                                                <div className="menu-header-content text-left">
                                                    <div className="widget-content p-0">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left mr-3">
                                                                <img width={42} className="rounded-circle" src={avatar1}
                                                                     alt=""/>
                                                            </div>
                                                            <div className="widget-content-left">
                                                                <div className="widget-heading">
                                                                    Alina Mcloughlin
                                                                </div>
                                                                <div className="widget-subheading opacity-8">
                                                                    A short profile description
                                                                </div>
                                                            </div>
                                                            <div className="widget-content-right mr-2">
                                                                <Button onClick={logout} className="btn-pill btn-shadow btn-shine"
                                                                        color="focus">
                                                                    Logout
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className="widget-content-left  mr-3 header-user-info">
                                <div className="widget-heading">
                                    Admin Name
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default UserBox;